import { useContext } from 'react'
import { FilterContext } from '../../context/FilterContext'

import Link from 'next/link'
import { useRouter } from 'next/router'

const Footer = () => {

  // Filter context
  const FilterContextState = useContext(FilterContext)

  const router = useRouter()

  const triggerProject = (e) => {
    FilterContextState.selectFilter(e.target.dataset)
    router.push(`/projects`)
  }

  return (
    <div className="Footer">
      <div className="Footer-container">
        <div className="Footer-inner">
          <div className="Footer-mainMenu">
            <div className="Footer-menuWrap">
              <ul className="Footer-list">
                <li><Link href={`/vision`} as={`/vision`}><a>Vision</a></Link></li>
                <li><Link href={`/projects`} as={`/projects`}><a><span>All </span>projects</a></Link></li>
                <li><Link href={`/news`} as={`/news`}><a>News</a></Link></li>
              </ul>
            </div>
          </div>
          <div className="Footer-subMenu">
            <div className="Footer-menuWrap">
              <ul className="Footer-list">
                <li><Link href={`/vision/[slug]`} as={`/vision/innovative`}><a>Innovative</a></Link></li>
                <li><Link href={`/vision/[slug]`} as={`/vision/connective`}><a>Connective</a></Link></li>
                <li><Link href={`/vision/[slug]`} as={`/vision/responsible`}><a>Responsible</a></Link></li>
                <li><Link href={`/vision/[slug]`} as={`/vision/mixed`}><a>Mixed</a></Link></li>
              </ul>
              <ul className="Footer-list">
                <li><a data-category="type" data-id="729" data-title="Masterplanning" href="#" onClick={(e) => triggerProject(e)}>Masterplanning</a></li>
                <li><a data-category="type" data-id="730" data-title="Urban design" href="#" onClick={(e) => triggerProject(e)}>Urban design</a></li>
                <li><a data-category="type" data-id="731" data-title="Offices and headquarters" href="#" onClick={(e) => triggerProject(e)}>Offices and Headquarters</a></li>
                <li><a data-category="type" data-id="732" data-title="Airports" href="#" onClick={(e) => triggerProject(e)}>Airports</a></li>
                <li><a data-category="type" data-id="733" data-title="Education" href="#" onClick={(e) => triggerProject(e)}>Education</a></li>
              </ul>
              <ul className="Footer-list">
                <li><Link href={`/services`} as={`/services`}><a>Services</a></Link></li>
                <li><Link href={`/about`} as={`/about`}><a>About</a></Link></li>
                <li><Link href={`/people`} as={`/people`}><a>People</a></Link></li>
              </ul>
              <ul className="Footer-list">
                <li><Link href={`/[generic]`} as={`/origins`}><a>Origins</a></Link></li>
                <li><a href="https://benthem-crouwel-architects.homerun.co/" target="_blank" rel="noreferrer noopener">Careers</a></li>
                <li><Link href={`/awards`} as={`/awards`}><a>Awards</a></Link></li>
              </ul>
            </div>
          </div>
          <div className="Footer-metaMenu">
            <ul className="Footer-list">
              <li><Link href={`/`} as={`/`}><a>© 2020 — Benthem crouwel architects</a></Link></li>
              <li><Link href={`/[generic]`} as={`/privacy`}><a>Privacy statement</a></Link></li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Footer
