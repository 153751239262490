import { useEffect, useRef, useState, useContext } from 'react'

// external components
import Swiper from 'swiper'

// context
import { FilterContext } from '../../context/FilterContext'

const CardList = (props) => {
  const { layout, type, title, heading } = props

  let modifier = (layout !== 'default' && layout) ? ` CardList--${layout}` : ''
  modifier = type ? `${modifier} CardList--${type}` : modifier

  const carouselBase = useRef()
  const carouselContainer = useRef()
  const carouselWrapper = useRef()
  const carouselPagination = useRef()
  const carouselButtonNext = useRef()
  const carouselButtonPrev = useRef()

  const [isMobile, setIsMobile] = useState(false)
  const [isCarousel, setIsCarousel] = useState(false)
  const [carousel, setCarousel] = useState(false)

  const FilterContextState = useContext(FilterContext)


  useEffect(()=> {
    if (layout && layout === 'spread' && isCarousel) {
      const carouselContainerClass = carouselContainer.current.classList[0]
      const carouselWrapperClass = carouselWrapper.current.classList[0]
      const slideCardClass = carouselWrapper.current.firstChild.classList[0]
      const carouselPaginationClass = carouselPagination.current.classList[0]
      const spaceBetween = (isMobile) ? 2 : 20
      const slidesOffsetBefore = (isMobile) ? 30 : 0
      const slidesOffsetAfter = (isMobile) ? 30 : 0

      const swiper = new Swiper(carouselContainer.current, {
        slidesPerView: 'auto',
        containerModifierClass: `${carouselContainerClass}--`,
        wrapperClass: carouselWrapperClass,
        slideClass: slideCardClass,
        slideActiveClass: `${slideCardClass}--active`,
        slideNextClass: `${slideCardClass}--next`,
        spaceBetween: spaceBetween,
        slidesOffsetBefore: slidesOffsetBefore,
        slidesOffsetAfter: slidesOffsetAfter,
        observer: true,
        keyboard: {
          enabled: true,
        },
        pagination: {
          el: carouselPagination.current,
          modifierClass: `${carouselPaginationClass}--`,
          bulletClass: `${carouselPaginationClass}Bullet`,
          bulletActiveClass: `${carouselPaginationClass}Bullet--active`,
          clickableClass: `${carouselPaginationClass}--clickable`,
          clickable: true
        },
        navigation: {
          nextEl: carouselButtonNext.current,
          prevEl: carouselButtonPrev.current,
          disabledClass: 'is-disabled'
        },
        on: {
          init: function () {
            carouselBase.current.classList.add('is-active')
          }
        }
      })

      setCarousel(swiper)

    } else {
      if (carousel) {
        carousel.destroy(false, true)
      }
    }
  },[isCarousel])

  useEffect(() => {
    if (layout && layout === 'spread') {
      setIsCarousel(carouselContainer.current.clientWidth < carouselWrapper.current.scrollWidth)
      setIsMobile(window.innerWidth <= 1023)

      const resizeEventListener = () => {
        setIsCarousel(carouselContainer.current.clientWidth < carouselWrapper.current.scrollWidth)
        setIsMobile(window.innerWidth <= 1023)
      }

      window.addEventListener('resize', resizeEventListener)
      return () => window.removeEventListener('resize', resizeEventListener)
    }
  }, [])

  return (
    <div className={`CardList${modifier}${FilterContextState.selectedFilters.length >= 1 ? ` is-filtered` : ``}`} ref={carouselBase}>
      <div className="CardList-inner">

        { title &&
        <div className="CardList-title">
          <h1>{ title }</h1>
        </div>
        }

        { heading &&
        <div className="CardList-heading">
          <h2>{ heading }</h2>
        </div>
        }

        <div className={`CardList-container`} ref={carouselContainer}>
          <div className={`CardList-wrapper`} ref={carouselWrapper}>
            { props.children }
          </div>

          {isCarousel &&
            <>
            <div className="CardList-pagination" ref={carouselPagination}></div>

            <div className="CardList-navigation CardList-navigation--prev" onClick={()=>{ carousel.slidePrev() }} ref={carouselButtonPrev}>
              <div className="CardList-navigationButton">
                <svg className="CardList-navigationIcon">
                  <use xlinkHref="#icon-carousel-prev"/>
                </svg>
              </div>
            </div>

            <div className="CardList-navigation CardList-navigation--next" onClick={()=>{ carousel.slideNext() }} ref={carouselButtonNext}>
              <div className="CardList-navigationButton">
                <svg className="CardList-navigationIcon">
                  <use xlinkHref="#icon-carousel-next"/>
                </svg>
              </div>
            </div>

            </>
          }

        </div>
      </div>
    </div>
  )
}

export default CardList
